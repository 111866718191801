var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 pl-4 mb-4"},[_c('v-card',{staticClass:"pa-5",attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('p',{staticClass:"display-1 text--primary"},[_vm._v(_vm._s(_vm.$tc('members.heading', _vm.getMembers.length)))]),_c('p',[_vm._v(_vm._s(_vm.$tc('members.subheading', _vm.getMembers.length)))])]),_c('div',{staticClass:"flex flex-column"},[_c('div',{staticClass:"pb-4"},[_c('search',{ref:"searchComponent",attrs:{"search-value":_vm.search,"adjutable":""},on:{"emit-switch-type":_vm.emitSwitchType,"input":function($event){_vm.search = $event}}})],1),(_vm.getMembers.length)?_c('div',[_c('v-data-table',{staticClass:"elevation-2 cursor-pointer",attrs:{"headers":_vm.headers,"items":_vm.getMembers,"search":_vm.search,"custom-filter":_vm.customFilter,"loading":_vm.$store.getters.getLoaders.members,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"loading-text":_vm.$t('common.datatable.loading'),"no-data-text":_vm.$t('common.datatable.noData'),"no-results-text":_vm.$t('common.datatable.noResults'),"items-per-page":25,"footer-props":{
                      'items-per-page-options': [25, 50, 100, -1],
                      'items-per-page-text': _vm.$t('common.datatable.rowsPerPage'),
                      'items-per-page-all-text': _vm.$t('common.words.all')
                  }},on:{"update:expanded":function($event){_vm.expanded=$event},"contextmenu:row":_vm.showContextMenu,"click:row":_vm.goToMember},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getRowFontColor(item)) + ";backgroud-color:#ff0000"))},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getRowFontColor(item))))},[_vm._v(" "+_vm._s((item.firstname ? ((item.firstname) + " " + (item.name)) : item.name))+" ")])]}},{key:"item.company",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getRowFontColor(item))))},[_vm._v(" "+_vm._s(item.company)+" ")])]}},{key:"item.fewoversion_full",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getRowFontColor(item))))},[_vm._v(" "+_vm._s(item.fewoversion_full)+" ")])]}},{key:"item.member_totalproperties",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getRowFontColor(item))))},[_vm._v(" "+_vm._s(item.member_totalproperties)+" ")])]}},{key:"item.registered_properties",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getRowFontColor(item))))},[_vm._v(" "+_vm._s(item.registered_properties)+" ")])]}},{key:"item.sales_potential",fn:function(ref){
                  var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getSalesPotentialColor(item))))},[_vm._v(" "+_vm._s(item.sales_potential)+" ")])]}},{key:"item.feondi_uebertragungabgeschlossen",fn:function(ref){
                  var item = ref.item;
return [(item.fewoversion_full)?_c('v-layout',{staticClass:"justify-space-around"},[(!item.feondi_uebertragungabgeschlossen)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({attrs:{"size":15,"indeterminate":"","color":_vm.$store.getters.getColors.error}},'v-progress-circular',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getMemberSyncSinceFormat(item.feondi_deletealltstamp)))])]):_c('v-icon',{attrs:{"x-small":"","color":_vm.$store.getters.getColors.success}},[_vm._v(" mdi-checkbox-blank-circle ")])],1):_vm._e()]}}],null,false,2557219413)}),_c('v-menu',{attrs:{"position-x":_vm.x,"position-y":_vm.y,"absolute":"","offset-y":""},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{attrs:{"min-width":"220px"}},_vm._l((_vm.contextItems),function(contextItem,contextIndex){return _c('v-layout',{key:("contextIdex-" + contextIndex)},[(contextItem.condition())?_c('v-list-item',{staticStyle:{"cursor":"pointer"},attrs:{"ripple":"","color":_vm.getColors.secondary1},on:{"click":contextItem.action}},[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',{attrs:{"size":"24px","color":contextItem.icon().color}},[_vm._v(" "+_vm._s(contextItem.icon().name)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(contextItem.title())+" ")])],1)],1):_vm._e()],1)}),1)],1)],1):_c('div',[_c('skeleton')],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }