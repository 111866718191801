<template>
    <div class="w-100 pl-4 mb-4">
        <v-card class="pa-5" outlined>
            <v-card-text class="pa-0">
                <p class="display-1 text--primary">{{$tc('members.heading', getMembers.length)}}</p>
                <p>{{$tc('members.subheading', getMembers.length)}}</p>
            </v-card-text>
            <div class="flex flex-column">
                <div class="pb-4">
                    <search
                        :search-value="search"
                        adjutable
                        ref="searchComponent"
                        @emit-switch-type="emitSwitchType"
                        @input="search = $event"
                    />
                </div>
                <div v-if="getMembers.length">
                    <v-data-table
                        :headers="headers"
                        :items="getMembers"
                        :search="search"
                        :custom-filter="customFilter"
                        :loading="$store.getters.getLoaders.members"
                        :single-expand="singleExpand"
                        :expanded.sync="expanded"
                        :loading-text="$t('common.datatable.loading')"
                        :no-data-text="$t('common.datatable.noData')"
                        :no-results-text="$t('common.datatable.noResults')"
                        :items-per-page="25"
                        :footer-props="{
                          'items-per-page-options': [25, 50, 100, -1],
                          'items-per-page-text': $t('common.datatable.rowsPerPage'),
                          'items-per-page-all-text': $t('common.words.all')
                      }"
                        class="elevation-2 cursor-pointer"
                        @contextmenu:row="showContextMenu"
                        @click:row="goToMember"
                    >
                        <template v-slot:item.id="{ item }">
                            <span :style="`color: ${getRowFontColor(item)};backgroud-color:#ff0000`">
                                {{item.id}}
                            </span>
                        </template>
                        <template v-slot:item.name="{ item }">
                            <span :style="`color: ${getRowFontColor(item)}`">
                                {{(item.firstname ? `${item.firstname} ${item.name}` : item.name)}}
                            </span>
                        </template>
                        <template v-slot:item.company="{ item }">
                            <span :style="`color: ${getRowFontColor(item)}`">
                                {{item.company}}
                            </span>
                        </template>
                        <template v-slot:item.fewoversion_full="{ item }">
                            <span :style="`color: ${getRowFontColor(item)}`">
                                {{item.fewoversion_full}}
                            </span>
                        </template>
                        <template v-slot:item.member_totalproperties="{ item }">
                            <span :style="`color: ${getRowFontColor(item)}`">
                                {{item.member_totalproperties}}
                            </span>
                        </template>
                        <template v-slot:item.registered_properties="{ item }">
                            <span :style="`color: ${getRowFontColor(item)}`">
                                {{item.registered_properties}}
                            </span>
                        </template>
                        <template v-slot:item.sales_potential="{ item }">
                            <span :style="`color: ${getSalesPotentialColor(item)}`">
                                {{item.sales_potential}}
                            </span>
                        </template>
                        <template v-slot:item.feondi_uebertragungabgeschlossen="{ item }">
                            <v-layout class="justify-space-around" v-if="item.fewoversion_full">
                                <v-tooltip bottom v-if="!item.feondi_uebertragungabgeschlossen">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-progress-circular
                                            :size="15"
                                            indeterminate
                                            :color="$store.getters.getColors.error"
                                            v-bind="attrs"
                                            v-on="on"
                                        />
                                    </template>
                                    <span>{{getMemberSyncSinceFormat(item.feondi_deletealltstamp)}}</span>
                                </v-tooltip>
                                <v-icon v-else x-small :color="$store.getters.getColors.success">
                                    mdi-checkbox-blank-circle
                                </v-icon>
                            </v-layout>
                        </template>
                    </v-data-table>
                    <v-menu
                        v-model="showMenu"
                        :position-x="x"
                        :position-y="y"
                        absolute
                        offset-y
                    >
                        <v-list min-width="220px">
                            <v-layout v-for="(contextItem, contextIndex) in contextItems"
                                      :key="`contextIdex-${contextIndex}`">
                                <v-list-item
                                    ripple
                                    v-if="contextItem.condition()"
                                    style="cursor: pointer"
                                    :color="getColors.secondary1"
                                    @click="contextItem.action"
                                >
                                    <v-list-item-action class="mr-3">
                                        <v-icon size="24px" :color="contextItem.icon().color">
                                            {{contextItem.icon().name}}
                                        </v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{contextItem.title()}}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-layout>
                        </v-list>
                    </v-menu>
                </div>
                <div v-else>
                    <skeleton/>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import Search from '@/components/Allgemein/Suche.vue'
import Skeleton from '@/components/Allgemein/Skeleton'
import socket from '@/mixins/socket.mixin'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    name: 'Members',
    components: { Search, Skeleton },
    mixins: [socket],
    metaInfo: {
        title: 'Members',
    },
    data() {
        return {
            customFilterEnabled: (this.$cookies.get('searchType') === 'true'),
            clickedRow: null,
            showMenu: false,
            x: 0,
            y: 0,
            contextItems: [
                {
                    condition: () => {
                        return true
                    },
                    title: () => {
                        return 'Edit'
                    },
                    icon: () => {
                        return {
                            name: 'mdi-account-edit-outline',
                            color: this.getColors.primary,
                        }
                    },
                    action: () => {
                        if (this.clickedRow) {
                            const item = this.clickedRow.item

                            logger.log(`this is item in context menu edit: `, item)
                            this.goToMember(null, this.clickedRow)
                        }
                    },
                },
                {
                    condition: () => {
                        if (this.clickedRow) {
                            const item = this.clickedRow.item
                            return item.id !== this.getUser.id && item.feondi_uebertragungabgeschlossen
                        }

                        return false
                    },
                    title: () => {
                        return this.displayValues().text
                    },
                    icon: () => {
                        return {
                            name: this.displayValues().icon.name,
                            color: this.displayValues().icon.color,
                        }
                    },
                    action: async () => {
                        if (this.clickedRow) {
                            const item = this.clickedRow.item
                            await this.startActingAsMember(item.id)
                        }
                    },
                },
            ],
            coloredColumns: [
                'id',
                'firstname',
                'name',
                'company',
                'fewoversion_full',
            ],
            expanded: [],
            singleExpand: false,
            search: '',
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getUser',
            'getActAsMember',
            'getMembers',
            'getMember',
        ]),
        headers: function () {
            return [
                {
                    text: this.$i18n.t('common.labels.id'),
                    value: 'id',
                    align: 'end',
                    divider: true,
                    width: '90px',
                },
                {
                    text: this.$i18n.t('common.labels.name'),
                    value: 'name',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.company'),
                    value: 'company',
                    divider: true,
                    width: '200px',
                },
                {
                    text: this.$i18n.t('common.labels.active'),
                    value: 'member_totalproperties',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.registered'),
                    value: 'registered_properties',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.potential'),
                    value: 'sales_potential',
                    align: 'end',
                    divider: true,
                    width: '120px',
                },
                {
                    text: 'Fewo',
                    value: 'fewoversion_full',
                    divider: true,
                    width: '120px',
                },
                {
                    text: this.$i18n.t('common.labels.membersync'),
                    value: 'feondi_uebertragungabgeschlossen',
                    align: 'center',
                    divider: true,
                    width: '150px',
                },
            ]
        },
    },
    methods: {
        emitSwitchType(value) {
            this.customFilterEnabled = value
        },
        customFilter(value, search, item) {

            let test = false

            if (!this.customFilterEnabled) {
                //Info: check only numeric search values
                if (/\d/.test(search))
                    test = item.hasOwnProperty('id') && item.id.toString().includes(search.toString())

                //Info: check only alpha numeric search values
                if (/([a-zA-z])/.test(search))
                    test = (item.hasOwnProperty('firstname') && item.firstname.toString().toLowerCase().includes(search.toString().toLowerCase())) || (item.hasOwnProperty('name') && item.name.toString().toLowerCase().includes(search.toString().toLowerCase())) || (item.hasOwnProperty('company') && item.company.toString().toLowerCase().includes(search.toString().toLowerCase()))
            }
            else
                test = (item.hasOwnProperty('id') && item.id.toString().includes(search.toString())) || ((item.hasOwnProperty('firstname') && item.firstname.toString().toLowerCase().includes(search.toString().toLowerCase())) || (item.hasOwnProperty('name') && item.name.toString().toLowerCase().includes(search.toString().toLowerCase())) || (item.hasOwnProperty('company') && item.company.toString().toLowerCase().includes(search.toString().toLowerCase())))

            return test
        },
        getRowFontColor(rowItem) {
            if (!rowItem.feondi_uebertragungabgeschlossen && rowItem.fewoversion_full)
                return this.getColors.error
            else if (!rowItem.fewoversion_full)
                return '#c9c9c9'

            return '#000000'
        },
        showContextMenu(e, row) {
            this.clickedRow = row

            e.preventDefault()

            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY

            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        getSalesPotentialColor(item) {

            if (!item.fewoversion_full)
                return '#c9c9c9'

            if (!item.feondi_uebertragungabgeschlossen)
                return this.getColors.error

            if (item.sales_potential <= 0)
                return this.getColors.error
            else if (item.sales_potential > 0 && item.sales_potential < 10)
                return this.getColors.warning
            else if (item.sales_potential >= 10)
                return this.getColors.success
            else
                return ''
        },
        getMemberSyncSinceFormat(timestamp) {
            if (!timestamp)
                return 'No further information available'
            else
                return moment.unix(timestamp).fromNow()
        },
        selectText(e) {
            e.target.setSelectionRange(0, this.search.length)
        },
        async goToMember(e, row) {
            const item = row.item

            await this.startActingAsMember(item.id)
            this.$store.commit('SET_MEMBER', parseInt(item.id))
            await this.$router.push({ path: `/member/${item.id}` })
        },
        displayValues() {
            let iconName = ''
            let iconColor = ''
            let text = ''

            if (this.clickedRow) {
                const item = this.clickedRow.item
                const itemMemberid = item.id
                const getMemberid = this.getMember.id

                iconName = 'mdi-account-convert-outline'
                iconColor = this.getColors.primary
                text = `Act as member ${itemMemberid}`

                if (this.getActAsMember && getMemberid && itemMemberid && getMemberid === itemMemberid) {
                    iconName = 'mdi-account-arrow-left-outline'
                    iconColor = this.getColors.warning
                    text = `Stop acting`
                }
            }

            return {
                icon: {
                    name: iconName,
                    color: iconColor,
                },
                text: text,
            }
        },
        async startActingAsMember(memberid) {
            await this.$store.dispatch('startActingAsMember', memberid)
        },
    },
    async created() {
        this.members = await this.$store.dispatch('getAndSetMembers')
        await this.$store.dispatch('stopActingAsMember')
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.$store.dispatch('resetForms')
            await vm.$store.dispatch('resetProps', [
                'tab',
                'memberChannels',
                'policies',
                'interfaces',
                'interface',
                'channelProperties',
                'channelProperty',
            ])
        })
    },
}
</script>
